// ** Initial State
const initialState = {
  pageData: [],
  errorMessage: null
  // status: null // 1 - fetching // 2 - success // 3 - error
}

const insights = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA':
      return { ...state, pageData: action.data }
    case 'DATA_ERROR':
      return { ...state, errorMessage: action.error }
    case 'DATA_STATUS':
      return { ...state, status: action.status }
    default:
      return { ...state }
  }
}
export default insights
