// ** Initial State
const initialState = {
    allPages: [],
    pageData: [],
    errorMessage: null,
    status: null // 1 - fetching // 2 - success // 3 - error
  }
  
  const contentPages = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_PAGES':
        return { ...state, allPages: action.data }
      case 'GET_PAGE_DATA':
        return { ...state, pageData: action.data }
      case 'DATA_ERROR':
        return { ...state, errorMessage: action.error }
      case 'DATA_STATUS':
        return { ...state, status: action.status }
      default:
        return { ...state }
    }
  }
  export default contentPages
  