// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import insights from '../../views/PetListing/store/reducer'
import contentPages from '../../views/ContentPages/store/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  insights,
  contentPages
})

export default rootReducer
